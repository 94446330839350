import { useState } from "react"

import cn from "classnames"
import dynamic from "next/dynamic"
import Link from "next/link"

import FaIcon from "components/FontAwesomeIcon"
import styles from "styles/button.module.scss"

import type { IconName } from "@fortawesome/fontawesome-svg-core"
import type { FaLibraries } from "components/FontAwesomeIcon"
const Modal = dynamic(() => import("components/Modal"))
const ContactForm = dynamic(() => import("components/forms/ContactForm"))
const RequestCallbackForm = dynamic(
  () => import("components/forms/RequestCallback")
)

export type TBtnVariants =
  | "default"
  | "primary"
  | "secondary"
  | "white"
  | "black"
  | "gray-100"
  | "gray-200"
  | "gray-300"
  | "gray-400"
  | "outlinePrimary"
  | "outlineSecondary"
  | "outlineWhite"
  | "outlineBlack"
  | "linkStyle"
  | "error"
  | "success"
  | "warning"
  | "outlineError"
  | "outlineSuccess"
  | "outlineWarning"

export type TBtnSizes = "sm" | "md" | "lg"

export type TBtnKinds =
  | "href"
  | "email"
  | "phone"
  | "contactFormPopUp"
  | "requestCallbackFormPopUp"
  | "visual"
  | "submit"

export interface IButton extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode
  content?: string
  href?: string
  kind?: TBtnKinds
  variant?: TBtnVariants
  icon?: IconName
  iconLibrary?: FaLibraries
  iconPosition?: "left" | "right"
  iconSpin?: boolean
  size?: TBtnSizes
  disabled?: boolean
}

export const Button: React.FC<IButton> = ({
  children,
  kind = "href",
  variant = "primary",
  icon,
  iconLibrary = "solid",
  iconPosition = "left",
  iconSpin = false,
  href = null,
  size = "md",
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false)
  const btnClass = cn(styles.btn, {
    [styles[size]]: size,
    [styles[variant]]: variant,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: btnClass }

  const internal = href && /^\/(?!\/)/.test(href)
  if (internal) {
    return (
      <Link href={href} {...rest}>
        {icon && iconPosition === "left" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="mr-2"
          />
        )}
        {children}
        {icon && iconPosition === "right" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="ml-2"
          />
        )}
      </Link>
    )
  }

  if (kind === "visual") {
    return (
      <button type="button" {...rest}>
        {icon && iconPosition === "left" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="mr-2"
          />
        )}
        {children}
        {icon && iconPosition === "right" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="ml-2"
          />
        )}
      </button>
    )
  }

  if (kind === "email") {
    return (
      <a
        href={`mailto:${process.env.NEXT_PUBLIC_EMAIL}`}
        title="Email us"
        {...rest}
      >
        {iconPosition === "left" && (
          <FaIcon
            icon={icon || "envelope"}
            library={iconLibrary}
            spin={iconSpin}
            className="mr-2"
          />
        )}
        {children || `Email us - ${process.env.NEXT_PUBLIC_EMAIL}`}
        {iconPosition === "right" && (
          <FaIcon
            icon={icon || "envelope"}
            library={iconLibrary}
            spin={iconSpin}
            className="ml-2"
          />
        )}
      </a>
    )
  }

  if (kind === "phone") {
    return (
      <a
        href={`tel:${process.env.NEXT_PUBLIC_TEL.replace(/\s+/g, "")}`}
        title="Call us"
        {...rest}
      >
        {iconPosition === "left" && (
          <FaIcon
            icon={icon || "phone"}
            library={iconLibrary}
            spin={iconSpin}
            className="mr-2"
          />
        )}
        {children || `Call us - ${process.env.NEXT_PUBLIC_TEL}`}
        {iconPosition === "right" && (
          <FaIcon
            icon={icon || "phone"}
            library={iconLibrary}
            spin={iconSpin}
            className="ml-2"
          />
        )}
      </a>
    )
  }

  if (kind === "contactFormPopUp") {
    return (
      <>
        <button onClick={() => setShowModal(true)} {...rest}>
          {iconPosition === "left" && (
            <FaIcon
              icon={icon || "envelope"}
              library={iconLibrary}
              spin={iconSpin}
              className="mr-2"
            />
          )}
          {children || `Send us a message`}
          {iconPosition === "right" && (
            <FaIcon
              icon={icon || "envelope"}
              library={iconLibrary}
              spin={iconSpin}
              className="ml-2"
            />
          )}
        </button>
        <Modal
          title={
            <>
              What can we do <span className="text-tertiary">for you?</span>
            </>
          }
          getState={showModal}
          setState={() => setShowModal(prevState => !prevState)}
        >
          <ContactForm />
        </Modal>
      </>
    )
  }

  if (kind === "requestCallbackFormPopUp") {
    return (
      <>
        <button onClick={() => setShowModal(true)} {...rest}>
          {iconPosition === "left" && (
            <FaIcon
              icon={icon || "phone-volume"}
              library={iconLibrary}
              spin={iconSpin}
              className="mr-2"
            />
          )}
          {children || `Request callback`}
          {iconPosition === "right" && (
            <FaIcon
              icon={icon || "phone-volume"}
              library={iconLibrary}
              spin={iconSpin}
              className="ml-2"
            />
          )}
        </button>
        <Modal
          title={
            <>
              Request <span className="text-secondary">callback</span>
            </>
          }
          getState={showModal}
          setState={() => setShowModal(prevState => !prevState)}
        >
          <RequestCallbackForm />
        </Modal>
      </>
    )
  }

  if (kind === "submit") {
    return (
      <button type="submit" {...rest}>
        {icon && iconPosition === "left" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="mr-2"
          />
        )}
        {children}
        {icon && iconPosition === "right" && (
          <FaIcon
            icon={icon}
            library={iconLibrary}
            spin={iconSpin}
            className="ml-2"
          />
        )}
      </button>
    )
  }

  return (
    <a href={href || ""} rel="noopener noreferrer" target="_blank" {...rest}>
      {iconPosition === "left" && (
        <FaIcon
          icon={icon ?? "external-link"}
          library={iconLibrary}
          spin={iconSpin}
          className="mr-2"
        />
      )}
      {children}
      {iconPosition === "right" && (
        <FaIcon
          icon={icon ?? "external-link"}
          library={iconLibrary}
          spin={iconSpin}
          className="ml-2"
        />
      )}
    </a>
  )
}

export const ActionButton: React.FC<
  JSX.IntrinsicElements["button"] & {
    isLoading: boolean
    loadingText?: string
    kind?: TBtnKinds
    size?: TBtnSizes
    variant?: TBtnVariants
    icon?: IconName
    iconLibrary?: "solid" | "brands"
    iconPosition?: "left" | "right"
    iconSpin?: boolean
  }
> = ({
  children,
  isLoading,
  loadingText,
  kind = "visual",
  size = "md",
  variant,
  icon,
  iconLibrary = "solid",
  iconPosition = "left",
  iconSpin = false,
  ...rest
}) => {
  return (
    <Button
      kind={kind}
      size={size}
      variant={variant}
      disabled={isLoading}
      {...rest}
    >
      {isLoading ? (
        <>
          {iconPosition === "left" && (
            <FaIcon icon="spinner-third" className="mr-2" spin />
          )}
          {loadingText ?? "Submitting"}
          {iconPosition === "right" && (
            <FaIcon icon="spinner-third" className="ml-2" spin />
          )}
        </>
      ) : (
        <>
          {icon && iconPosition === "left" && (
            <FaIcon
              icon={icon}
              library={iconLibrary}
              spin={iconSpin}
              className="mr-2"
            />
          )}
          {children}
          {icon && iconPosition === "right" && (
            <FaIcon
              icon={icon}
              library={iconLibrary}
              spin={iconSpin}
              className="ml-2"
            />
          )}
        </>
      )}
    </Button>
  )
}

export const IconButton: React.FC<
  JSX.IntrinsicElements["button"] & {
    variant?: "black" | "white" | "error" | "success"
    icon: IconName
    iconLibrary?: "solid" | "brands"
    saving?: boolean
  }
> = ({
  variant = "black",
  icon,
  saving,
  iconLibrary = "solid",
  children,
  ...rest
}) => {
  const cl = cn(styles.btn, "bg-white p-2", {
    [styles.outlineBlack]: variant === "black",
    [styles.outlineWhite]: variant === "white",
    [styles.outlineError]: variant === "error",
    [styles.outlineSuccess]: variant === "success",
    "!flex items-center gap-1 text-sm uppercase": children,
    "opacity-50 cursor-not-allowed": saving,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: cl }
  return (
    <button type="button" {...rest}>
      <FaIcon
        icon={saving ? "spinner-third" : icon}
        spin={saving}
        library={iconLibrary}
        className="!block aspect-square"
        size="lg"
      />
      {children}
    </button>
  )
}

export interface IButtons extends React.HTMLAttributes<HTMLDivElement> {
  buttons: IButton[]
  evenDefaultVariant?: TBtnVariants
  oddDefaultVariant?: TBtnVariants
}

export const Buttons: React.FC<IButtons> = ({
  buttons,
  evenDefaultVariant = "primary",
  oddDefaultVariant = "outlinePrimary",
  ...rest
}) => {
  const c = cn("flex flex-wrap gap-1", {
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: c }
  return (
    <div {...rest}>
      {buttons.map((btn, k) => (
        <Button
          key={btn.id || `btn-${k}`}
          {...btn}
          variant={
            btn.variant === "default" || !btn.variant
              ? k % 2 == 0
                ? evenDefaultVariant
                : oddDefaultVariant
              : btn.variant
          }
        >
          {btn.content}
        </Button>
      ))}
    </div>
  )
}
