import cn from "classnames"
import Link from "next/link"

type TDropdownItem = React.HTMLAttributes<HTMLLIElement> & {
  label: string | React.ReactNode
  href?: string
}

interface IDropdown extends React.HTMLAttributes<HTMLDivElement> {
  Trigger: React.FC
  items: Array<TDropdownItem>
  variant?: "light" | "dark"
}

const Dropdown: React.FC<IDropdown> = ({
  Trigger,
  items,
  variant = "light",
  ...rest
}) => {
  const wrapperClass = cn("relative group ", {
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: wrapperClass }

  const ulClass = cn(
    "no-prose-list opacity-0 invisible group-hover:opacity-100 hover:opacity-100 group-hover:visible hover:visible shadow-lg absolute my-0 z-10 top-full left-0 mdmax:left-1/2 mdmax:-translate-x-1/2 transition-all",
    {
      "bg-gray-100": variant === "light",
      "bg-gray-900": variant === "dark"
    }
  )

  const liClass = cn("border-b last:border-b-0 hover:text-primary mb-0", {
    "hover:bg-white hover:text-primary": variant === "light",
    "hover:bg-gray-700 text-white": variant === "dark"
  })

  const aClass = cn(
    "p-3 leading-4 transition-none whitespace-nowrap font-normal flex items-center mdmax:justify-center mdmax:w-full mdmax:py-4 cursor-pointer"
  )

  return (
    <div {...rest}>
      <Trigger />
      <ul className={ulClass}>
        {items.map(item => {
          const { href, label } = item
          return (
            <li
              className={liClass}
              key={`dropdown-item-${label}`}
              itemScope
              itemType="https://www.schema.org/SiteNavigationElement"
              {...item}
            >
              {href ? (
                <Link href={href} className={aClass}>
                  {label}
                </Link>
              ) : (
                <span className={aClass}>{label}</span>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Dropdown
