import FaIcon from "./FontAwesomeIcon"
import { Container, Section } from "./Layout"
import { Lead } from "./Text"

const Usps: React.FC = () => {
  return (
    <Section variant="primary">
      <Container
        variant="full"
        className="grid smmax:grid-cols-2 mdmin:grid-cols-5 gap-4 text-center"
      >
        <div>
          <FaIcon icon="shield-check" className="text-white" size="3x" />
          <Lead variant="light" className="my-0 pt-3">
            Guaranteed
          </Lead>
        </div>
        <div>
          <FaIcon icon="cctv" className="text-white" size="3x" />
          <Lead variant="light" className="my-0 pt-3">
            Secure
          </Lead>
        </div>
        <div>
          <FaIcon icon="camera-home" className="text-white" size="3x" />
          <Lead variant="light" className="my-0 pt-3">
            High definition
          </Lead>
        </div>
        <div>
          <FaIcon icon="badge-check" className="text-white" size="3x" />
          <Lead variant="light" className="my-0 pt-3">
            Experienced
          </Lead>
        </div>
        <div className="smmax:col-span-2">
          <FaIcon icon="eye" className="text-white" size="3x" />
          <Lead variant="light" className="my-0 pt-3">
            Monitor anywhere
          </Lead>
        </div>
      </Container>
    </Section>
  )
}

export default Usps
