/**
 * Used to use the following but doesn't include our theme so merge defaultConfig with our theme
 * @see https://tailwindcss.com/docs/configuration#referencing-in-java-script
 *
 * Also the TW docs say to include the following type def at the top of tailwind.config.js but it doesn't include our theme
 * @see https://tailwindcss.com/docs/configuration#type-script-types
 */
import defaultConfig from "tailwindcss/defaultConfig"

import tailwindConfig from "tailwind.config"

const theme = {
  ...defaultConfig.theme,
  ...tailwindConfig.theme
}

const config = {
  ...defaultConfig,
  ...tailwindConfig,
  theme
}

export const twTheme = config.theme
export const twConfig = config
