import type { Dispatch, SetStateAction } from "react"

import cn from "classnames"

import styles from "styles/header.module.scss"

import type { IMenuOpen } from "./Header"

interface IHamburger extends React.HTMLAttributes<HTMLDivElement> {
  open: IMenuOpen
  setOpen: Dispatch<SetStateAction<IMenuOpen>>
  stuck: boolean
}

const Hamburger: React.FC<IHamburger> = ({ open, setOpen, stuck, ...rest }) => {
  const c = cn(styles.hamburger, {
    [styles.hamburgerOpen]: open.state,
    [styles.hamburgerStuck]: stuck,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: c }

  return (
    <div
      onClick={() =>
        setOpen(prev => ({
          state: !prev.state,
          animate: true
        }))
      }
      {...rest}
    >
      <span />
      <span />
      <span />
      <span>MENU</span>
    </div>
  )
}

export default Hamburger
