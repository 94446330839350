import Image from "next/image"

import { Buttons } from "./Button"
import { Container } from "./Layout"
import { Heading, SubHeading } from "./Text"

import type { IButtons } from "./Button"
import type { StaticImageData } from "next/image"

const Hero: React.FC<{
  image?: string | StaticImageData
  imageAlt?: string
  imageClass?: string
  heading: React.ReactNode
  subHeading?: React.ReactNode
  content?: React.ReactNode
  buttons?: IButtons
}> = ({
  image,
  imageAlt,
  imageClass,
  heading,
  subHeading,
  content,
  buttons
}) => {
  return (
    <div
      className={`relative flex items-center justify-center overflow-hidden bg-black ${
        !!image ? `min-h-[500px] ` : ``
      }`}
    >
      {image && (
        <Image
          src={image}
          alt={imageAlt || ""}
          placeholder="blur"
          priority
          className={`absolute w-full h-full top-0 left-0 object-cover object-bottom opacity-50 ${
            imageClass ? imageClass : ""
          }`}
          sizes="100vw"
        />
      )}
      <Container>
        <div className="py-8 relative text-center">
          <Heading
            variant="light"
            as="h1"
            underline={false}
            alignment="center"
            className="!mb-0"
          >
            {heading}
          </Heading>
          {subHeading && <SubHeading>{subHeading}</SubHeading>}
          {content && (
            <div className="text-white font-normal mx-auto mdmin:w-2/3 pb-3">
              {content}
            </div>
          )}
          {buttons && <Buttons {...buttons} />}
        </div>
      </Container>
    </div>
  )
}

export default Hero
