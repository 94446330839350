import { memo, useEffect, useRef, useState } from "react"

import cn from "classnames"
import dynamic from "next/dynamic"
import Link from "next/link"

import { InlineEmail, InlineTelephone } from "components/ContactDetails"
import FaIcon from "components/FontAwesomeIcon"
import Hamburger from "components/header/Hamburger"
import Logo from "components/Logo"
import styles from "styles/header.module.scss"
import { gsap } from "utils/gsap"

import Dropdown from "./Dropdown"
import NavLink from "./NavLink"
const Minicart = dynamic(() => import("components/ecom/cart/Minicart"), {
  ssr: false
})
export interface IMenuOpen {
  state: boolean
  animate: boolean
}

const Header = () => {
  const [open, setOpen] = useState<IMenuOpen>({ state: false, animate: false })
  const [stuck, setStuck] = useState<boolean>(false)
  const headerRef = useRef<HTMLElement>(null)
  const animation = useRef<GSAPAnimation | null>(null)

  useEffect(() => {
    if (stuck) {
      animation.current = gsap.fromTo(
        headerRef.current,
        {
          y: -100
        },
        {
          y: 0
        }
      )
      return () => {
        animation.current?.kill()
      }
    }
  }, [stuck])

  const handleScroll = () => setStuck(window.scrollY > 500)
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const headerClass = cn(styles.header, {
    [styles.headerStuck]: stuck
  })

  const ulClass = cn(styles.ul, {
    [styles.ulOpen]: open.state,
    [styles.ulClosed]: !open.state
  })

  return (
    <header className={headerClass} ref={headerRef}>
      <div className={styles.navContainer}>
        <Link
          href="/"
          className={styles.logo}
          title={`${process.env.NEXT_PUBLIC_SITE_NAME} - Back to homepage`}
        >
          {stuck ? <Logo light /> : <Logo />}
        </Link>
        <div className="flex mdmax:flex-row lgmin:flex-col mdmax:items-center lgmin:items-end mdmax:gap-2">
          <div className="flex gap-3">
            <div className="mdmax:hidden flex gap-3 border-b-2 border-b-primary">
              <InlineTelephone icon className={stuck ? "text-white" : ""} />
              <InlineEmail icon className={stuck ? "text-white" : ""} />
            </div>
            <Minicart variant={stuck ? "light" : "dark"} />
          </div>
          <nav className={styles.nav}>
            <ul className={`${ulClass} no-prose-list`}>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
              >
                <NavLink href="/" stuck={stuck}>
                  Home
                </NavLink>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
              >
                <Dropdown
                  Trigger={({ ...rest }) => {
                    return (
                      <NavLink as="button" stuck={stuck} {...rest}>
                        Services <FaIcon icon="chevron-down" className="ml-2" />
                      </NavLink>
                    )
                  }}
                  items={[
                    {
                      label: "Home CCTV",
                      href: "/services/home-cctv"
                    },
                    {
                      label: "Commercial CCTV",
                      href: "/services/commercial-cctv"
                    },
                    {
                      label: "Intruder alarms",
                      href: "/services/intruder-alarms"
                    },
                    {
                      label: "Access control",
                      href: "/services/access-control"
                    },
                    {
                      label: "Maintenance",
                      href: "/services/maintenance"
                    },
                    {
                      label: "Security gates",
                      href: "/services/security-gates"
                    },
                    {
                      label: "All services",
                      href: "/services"
                    }
                  ]}
                />
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
              >
                <NavLink href="/products" stuck={stuck}>
                  Online booking
                </NavLink>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
              >
                <NavLink href="/case-studies" stuck={stuck}>
                  Case studies
                </NavLink>
              </li>
              <li
                itemScope
                itemType="https://www.schema.org/SiteNavigationElement"
              >
                <NavLink
                  href="/contact-us"
                  stuck={stuck}
                  className="mdmin:!pr-0"
                >
                  Contact us
                </NavLink>
              </li>
              <li
                itemScope
                itemType=""
                className="lgmin:hidden text-center flex flex-wrap items-center justify-center gap-4 py-4"
              >
                <InlineTelephone icon />
                <InlineEmail icon />
              </li>
            </ul>
          </nav>
          <Hamburger open={open} setOpen={setOpen} stuck={stuck} />
        </div>
      </div>
    </header>
  )
}
export default memo(Header)
