import { useState } from "react"

import dynamic from "next/dynamic"
import Script from "next/script"

import type { UseFormReturn } from "react-hook-form"

const Alert = dynamic(() => import("../Alert"))

export interface IAlert {
  content: string
  show: boolean
  variant: "success" | "error"
}

export default function FormTemplate({
  rhf,
  formName,
  children
}: {
  rhf: UseFormReturn
  formName: string
  children: React.ReactNode
}): JSX.Element {
  const [alert, setAlert] = useState<IAlert>({
    content: "",
    show: false,
    variant: "success"
  })

  const sendForm = async () => {
    try {
      const formData = rhf.getValues()

      const recaptcha = await window.grecaptcha.execute(
        process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY
      )
      const recaptchaResponse = await fetch("/api/recaptcha", {
        method: "POST",
        body: JSON.stringify({ recaptcha }),
        headers: {
          "Content-Type": "application/json"
        }
      })

      if (recaptchaResponse.ok) {
        const response = await fetch("/api/sendEmail", {
          method: "POST",
          body: JSON.stringify({ formData, formName }),
          headers: {
            "Content-Type": "application/json"
          }
        })
        if (response.ok) {
          setAlert({
            content: `${formName} sent successfully`,
            show: true,
            variant: "success"
          })
          if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
              event: "formSubmit",
              formName: formName
            })
          }
        }
      }
    } catch (error) {
      setAlert({
        content:
          error instanceof Error ? error?.message : "Something went wrong",
        show: true,
        variant: "error"
      })
    } finally {
      // window.grecaptcha.reset() // reset recaptcha
      rhf.reset() // reset the form
      setTimeout(() => {
        setAlert(prev => ({ ...prev, show: false }))
      }, 5000)
    }
  }

  return (
    <>
      {rhf.formState.isDirty && (
        <Script
          strategy="lazyOnload"
          id="google-recaptcha"
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
        />
      )}
      <form className="relative" onSubmit={rhf.handleSubmit(sendForm)}>
        {children}
        <Alert variant={alert.variant} show={alert.show}>
          {alert.content}
        </Alert>
      </form>
    </>
  )
}
