import { useEffect, useRef, useState } from "react"

import Cookies from "js-cookie"

import { Button } from "components/Button"
import { gsap } from "utils/gsap"

const CookieNotice: React.FC = () => {
  const [showConsent, setShowConsent] = useState(false)
  const nodeRef = useRef(null)
  const animation = useRef<GSAPAnimation | null>(null)

  // if no cookie show the consent
  useEffect(() => {
    !Cookies.get("clientAcceptConsent") && setShowConsent(true)
    return () => {
      animation?.current?.kill()
    }
  }, [])

  // animate in
  useEffect(() => {
    if (showConsent) {
      animation.current = gsap.fromTo(
        nodeRef.current,
        { autoAlpha: 0, yPercent: 100 },
        { autoAlpha: 1, yPercent: 0 }
      )
    }
  }, [showConsent])

  // animate out
  const animateOut = () => {
    Cookies.set("clientAcceptConsent", "true", { expires: 30 })
    animation.current = gsap.fromTo(
      nodeRef.current,
      { yPercent: 0, autoAlpha: 1 },
      { yPercent: 100, autoAlpha: 0, onComplete: () => setShowConsent(false) }
    )
  }

  return showConsent ? (
    <div
      className="flex smmax:flex-col gap-4 justify-between mdmin:items-center bg-primary text-white p-3 fixed bottom-0 left-0 z-50 w-full"
      ref={nodeRef}
    >
      <p className="my-0 font-normal">
        This website uses cookies to ensure you get the best experience on our
        website.
      </p>

      <Button
        className="block"
        variant="white"
        kind="visual"
        onClick={animateOut}
      >
        Accept
      </Button>
    </div>
  ) : (
    <></>
  )
}

export default CookieNotice
