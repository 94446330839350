import dynamic from "next/dynamic"

import Hero from "components/Hero"
import Layout, { Container, Section } from "components/Layout"
import SEO from "components/Seo"
import ServicesGrid from "components/ServicesGrid"
import { Heading } from "components/Text"
import Usps from "components/Usps"
import heroImg from "public/home_hero.webp"
const Map = dynamic(() => import("components/Map"), { ssr: false })
const GoogleReviews = dynamic(() => import("components/GoogleReviews"), {
  ssr: false
})

import type { NextPage } from "next"

const Home: NextPage = () => {
  return (
    <>
      <SEO
        titleTag="CCTV Derbyshire | CCTV Installers Ripley | 3HCCTV &amp; Alarms"
        metaDescription="3H CCTV &amp; Alarms are specialist alarm &amp; CCTV installers based in Ripley and working across Derbyshire, Nottinghamshire &amp; South Yorkshire."
        pageTitle="Home"
      />
      <Layout>
        <Hero
          image={heroImg}
          imageAlt="CCTV &amp; Alarms Services Derbyshire"
          heading="We are 3H CCTV &amp; Alarms"
          subHeading="Fair &amp; friendly security when you need it"
          content={
            <>
              Voted <strong>Best Security Systems Provider - Derbyshire</strong>
              . Providing state of the art but reasonably prices CCTV, alarm and
              security systems to the Midlands for over 10 years.
            </>
          }
        />

        <Usps />

        <ServicesGrid />

        <Section variant="gray-200">
          <Container>
            <Heading>Safe and secure with 3H CCTV</Heading>
            <iframe
              className="w-full aspect-video"
              src={`https://www.youtube.com/embed/8vsQCWkihhI?modestbranding=1&rel=0&controls=0`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Container>
        </Section>

        <Map />
        <GoogleReviews />
      </Layout>
    </>
  )
}

export default Home
