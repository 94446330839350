import { useForm } from "react-hook-form"

import { Button } from "components/Button"
import FaIcon from "components/FontAwesomeIcon"
import { ErrorMessage, Input } from "components/forms/FormElements"
import FormTemplate from "components/forms/FormTemplate"

const RequestCallback: React.FC = () => {
  const rhf = useForm()

  return (
    <FormTemplate rhf={rhf} formName="Request callback">
      <div className="grid smmax:grid-cols-1 mdmin:grid-cols-4 mdmin:gap-3 items-center">
        <div>
          <Input
            variant="light"
            type="text"
            showRequired
            {...rhf.register("name", { required: true })}
          />
          {rhf.formState.errors.name && (
            <ErrorMessage>Please enter your name</ErrorMessage>
          )}
        </div>

        <div>
          <Input
            variant="light"
            type="tel"
            showRequired
            {...rhf.register("tel", { required: true })}
          />
          {rhf.formState.errors.tel && (
            <ErrorMessage>Please enter your telephone number</ErrorMessage>
          )}
        </div>

        <div>
          <Input
            variant="light"
            type="text"
            showRequired
            {...rhf.register("postcode", { required: true })}
          />
          {rhf.formState.errors.postcode && (
            <ErrorMessage>Please enter your postcode</ErrorMessage>
          )}
        </div>

        <Button
          kind="submit"
          variant="white"
          disabled={rhf.formState.isSubmitting}
        >
          {rhf.formState.isSubmitting ? (
            <>
              Submitting <FaIcon icon="spinner-third" spin />
            </>
          ) : (
            "Request callback"
          )}
        </Button>
      </div>
    </FormTemplate>
  )
}

export default RequestCallback
