import { forwardRef } from "react"

import cn from "classnames"
import { capitalize } from "lodash"

import FaIcon from "components/FontAwesomeIcon"
import styles from "styles/form-elements.module.scss"
import { slugify } from "utils/helpers"

export type FormVariants = "light" | "dark" | "admin"
export type InputSizes = "sm" | "md"
export const Input = forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements["input"] & {
    showPlaceholder?: boolean
    placeholder?: string
    showLabel?: boolean
    customLabel?: string // useful if more than 1 word as using rhf name for default label
    helpText?: string
    variant?: FormVariants
    inputSize?: InputSizes
    showRequired?: boolean
    as?: React.ElementType
    rows?: number // for textarea
  }
>(
  (
    {
      showPlaceholder = true,
      placeholder,
      showLabel = false,
      customLabel,
      helpText,
      variant = "dark",
      inputSize = "md",
      showRequired = false,
      ...rest
    },
    ref
  ) => {
    const inputClass = cn(styles.input, {
      [styles[`input-${variant}`]]: variant,
      [styles[`input-${inputSize}`]]: inputSize,
      [rest?.className as string]: !!rest?.className
    })
    rest = { ...rest, className: inputClass }

    const Tag = rest.as ? rest.as : "input"
    if (rest.as) {
      delete rest.as
    }

    return (
      <div className="w-full">
        {showLabel && (
          <div
            className={`font-bold ${
              inputSize === "sm" ? "text-xs -mb-1 mt-1" : "text-sm -mb-1 mt-2"
            }`}
          >
            <label htmlFor={rest.name && slugify(rest.name)}>
              {customLabel ?? capitalize(rest.name)}{" "}
              {showRequired && (
                <>
                  {" "}
                  <RequiredAsterix />
                </>
              )}
            </label>
          </div>
        )}
        <InputWrapper variant={variant} inputSize={inputSize as "md"}>
          <Tag
            {...rest}
            id={rest.name && slugify(rest.name)}
            ref={ref}
            placeholder=" "
          />
          {showPlaceholder && (
            <Placeholder
              variant={variant}
              inputSize={inputSize as "md"}
              htmlFor={rest.name && slugify(rest.name)}
            >
              {placeholder ?? capitalize(rest.name)}
              {showRequired && (
                <>
                  {" "}
                  <RequiredAsterix />
                </>
              )}
            </Placeholder>
          )}
        </InputWrapper>
        {helpText && (
          <div className="mb-3 -mt-1 text-sm text-gray-500">{helpText}</div>
        )}
      </div>
    )
  }
)
Input.displayName = "Input"

export const ErrorMessage: React.FC<{ children: React.ReactNode }> = ({
  children,
  ...rest
}) => {
  return (
    <div className={styles.errorMessage} {...rest}>
      {children}
    </div>
  )
}

export const SuccessMessage: React.FC<{ children: React.ReactNode }> = ({
  children,
  ...rest
}) => {
  return (
    <div className={styles.successMessage} {...rest}>
      {children}
    </div>
  )
}

export const InputWrapper: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    variant: FormVariants
    inputSize?: InputSizes
  }
> = ({ children, variant = "dark", inputSize = "md", ...rest }) => {
  const inputWrapperClass = cn(styles.inputWrapper, {
    [styles[`inputWrapper-${variant}`]]: variant,
    [styles[`inputWrapper-${inputSize}`]]: inputSize,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: inputWrapperClass }
  return <div {...rest}>{children}</div>
}

export const RequiredAsterix: React.FC = ({ ...rest }) => {
  return (
    <span className={styles.requiredAsterix} {...rest}>
      *
    </span>
  )
}

export const Placeholder: React.FC<
  JSX.IntrinsicElements["label"] & {
    variant?: FormVariants
    inputSize?: InputSizes
  }
> = ({ children, variant, inputSize = "md", ...rest }) => {
  const labelClass = cn(styles.placeholder, {
    [styles[`placeholder-${variant}`]]: variant,
    [styles[`placeholder-${inputSize}`]]: inputSize,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: labelClass }
  return <label {...rest}>{children}</label>
}

export const Checkboxes: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => {
  const checkboxesClass = cn(styles.checkboxes, {
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: checkboxesClass }
  return <div {...rest}>{children}</div>
}

export const Checkbox = forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements["input"] & {
    label: string
    value: string
  }
>(({ label, value, ...rest }, ref) => {
  const checkboxClass = cn(styles.checkbox, {
    [rest?.className as string]: !!rest?.className
  })
  // remove className from rest and apply to wrapper. Apply everything else to input
  delete rest.className
  return (
    <div className={checkboxClass}>
      <input
        type="checkbox"
        id={slugify(value)}
        value={value}
        ref={ref}
        {...rest}
      />
      <label htmlFor={slugify(value)}>{label}</label>
    </div>
  )
})
Checkbox.displayName = "Checkbox"

export const Radios: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => {
  const radiosClass = cn(styles.radios, {
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: radiosClass }
  return <div {...rest}>{children}</div>
}

export const Radio = forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements["input"] & {
    label: string
    value: string
  }
>(({ label, value, ...rest }, ref) => {
  const radioClass = cn(styles.radio, {
    [rest?.className as string]: !!rest?.className
  })
  // remove className from rest and apply to wrapper. Apply everything else to input
  delete rest.className
  return (
    <div className={radioClass}>
      <input
        type="radio"
        id={slugify(value)}
        value={value}
        ref={ref}
        {...rest}
      />
      <label htmlFor={slugify(value)}>{label}</label>
    </div>
  )
})
Radio.displayName = "Radio"

export const Select = forwardRef<
  HTMLSelectElement,
  JSX.IntrinsicElements["select"] & {
    options: {
      value: string
      label: string
      disabled?: boolean
    }[]
    showLabel?: boolean
    customLabel?: string
    helpText?: string
    inputSize?: InputSizes
    variant?: FormVariants
    showRequired?: boolean
  }
>(
  (
    {
      options,
      showLabel,
      customLabel,
      helpText,
      inputSize = "md",
      variant = "dark",
      showRequired = false,
      ...rest
    },
    ref
  ) => {
    const selectClass = cn(styles.input, styles.select, {
      [styles[`input-${variant}`]]: variant,
      [styles[`input-${inputSize}`]]: inputSize,
      [rest?.className as string]: !!rest?.className
    })
    rest = { ...rest, className: selectClass }

    return (
      <div className="w-full">
        {showLabel && (
          <div
            className={`font-bold ${
              inputSize === "sm" ? "text-xs -mb-1 mt-1" : "text-sm -mb-2 mt-2"
            }`}
          >
            <label htmlFor={rest.name && slugify(rest.name)}>
              {customLabel ?? capitalize(rest.name)}{" "}
              {showRequired && (
                <>
                  {" "}
                  <RequiredAsterix />
                </>
              )}
            </label>
          </div>
        )}
        <InputWrapper variant={variant} inputSize={inputSize}>
          <select {...rest} ref={ref}>
            {options.map(option => (
              <option
                key={`${rest.name}-${option.value}`}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))}
          </select>
          <FaIcon
            icon="chevron-down"
            className="absolute right-2 top-1/2 -translate-y-1/2"
          />
        </InputWrapper>
        {helpText && <p className="text-sm">{helpText}</p>}
      </div>
    )
  }
)
Select.displayName = "Select"

export const Switch = forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements["input"] & {
    label?: string
  }
>(({ label, ...rest }, ref) => {
  // take the className out of rest, drop into the switchClass and then spread the rest of rest into the input
  const switchClass = cn(styles.switch, {
    [styles.switchChecked]: rest.checked,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: "" }
  return (
    <div
      className={switchClass}
      {...(rest.onClick && { onClick: rest.onClick })}
    >
      <input type="checkbox" readOnly ref={ref} {...rest} />
      <div />
      {label && <label>{label}</label>}
    </div>
  )
})
Switch.displayName = "Switch"
