import { memo } from "react"

import dynamic from "next/dynamic"

import { Address, Email, Telephone } from "components/ContactDetails"
import { Container } from "components/Layout"
import SocialLinks from "components/SocialLinks"
const FBFeed = dynamic(() => import("components/FacebookFeed"), {
  ssr: false
})

import Logo from "./Logo"

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-gray-400 relative">
      <Container className="py-6 grid smmax:grid-cols-1 mdmin:grid-cols-2 gap-6">
        <div className="space-y-2">
          <Logo light className="pb-5" />
          <Address variant="light" />
          <Telephone variant="light" />
          <Email variant="light" />
          <SocialLinks variant="light" />
        </div>
        <div className="mdmin:mr-0 mdmin:ml-auto">
          <FBFeed />
        </div>
      </Container>
      <div className="bg-white py-2 text-center">
        <Container className="flex flex-wrap gap-2 mdmin:justify-between smmax:justify-center">
          <p className="my-0 font-normal">
            &copy; Copyright {new Date().getFullYear()}{" "}
            {process.env.NEXT_PUBLIC_SITE_NAME}
          </p>
        </Container>
      </div>
    </footer>
  )
}

export default memo(Footer)
