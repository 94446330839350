import cn from "classnames"
import Link from "next/link"

import FaIcon from "./FontAwesomeIcon"

import type { THrefVariants } from "types/types"

type Href = JSX.IntrinsicElements["a"] & {
  externalIconDisabled?: boolean
  passTargetToInternal?: boolean
  noUnderline?: boolean
  variant?: THrefVariants
}

const Href: React.FC<Href> = ({
  children,
  externalIconDisabled = false,
  variant,
  noUnderline,
  ...rest
}) => {
  if (!rest.href) return null
  const internal = /^\/(?!\/)/.test(rest.href)
  const validUrl = /(^http[s]?:\/{2})|(^www)|(^\/{1,2})/.test(rest.href)
  const hrefClass = cn({
    "with-underline": !noUnderline,
    "text-white": variant === "light",
    "text-black": variant === "dark",
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: hrefClass }

  if (internal) {
    return (
      <Link href={rest.href || ""} legacyBehavior={true}>
        <a {...rest}>{children}</a>
      </Link>
    )
  } else {
    return (
      <a
        href={rest.href}
        target={rest.target || "_blank"}
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
        {externalIconDisabled === false && validUrl ? (
          <FaIcon icon="external-link" className="ml-2" />
        ) : null}
      </a>
    )
  }
}

export default Href
