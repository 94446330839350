import cn from "classnames"

import type { TAlignments } from "types/types"

interface IHeading extends React.HTMLAttributes<HTMLHeadingElement> {
  children?: React.ReactNode
  small?: boolean
  alignment?: TAlignments
  underline?: boolean
  underlineVariant?: "primary" | "secondary"
  variant?: "dark" | "light"
  as?: React.ElementType
}
export const Heading: React.FC<IHeading> = ({
  children,
  small,
  alignment = "left",
  underline = true,
  underlineVariant = "primary",
  variant,
  ...rest
}) => {
  const headingClass = cn({
    "text-lg leading-8": small,
    "!text-white": variant === "light",
    "after:block after:w-[100px] after:h-1 after:mb-4 after:mt-1 after:rounded-sm":
      underline && !small,
    "after:block after:w-[75px] after:h-[4px] after:mb-4 after:mt-1 after:rounded-sm":
      underline && small,
    "after:bg-primary": underline && underlineVariant === "primary",
    "after:bg-secondary": underline && underlineVariant === "secondary",
    "after:ml-auto": underline && alignment === "right",
    "after:mr-auto": underline && alignment === "left",
    "after:mx-auto": underline && alignment === "center",
    "text-left": alignment === "left",
    "text-center": alignment === "center",
    "text-right": alignment === "right",
    [rest?.className as string]: !!rest?.className
  })
  const Tag = rest.as ? rest.as : "h2"
  if (rest.as) {
    delete rest.as
  }
  if (!rest.className) {
    delete rest.className
  }
  rest = { ...rest, className: headingClass }
  return <Tag {...rest}>{children}</Tag>
}

interface ISubHeading extends React.HTMLAttributes<HTMLHeadingElement> {
  children?: React.ReactNode
  variant?: "primary" | "black"
}
export const SubHeading: React.FC<ISubHeading> = ({
  children,
  variant = "primary",
  ...rest
}) => {
  const subHeadingClass = cn("!text-lg text-white p-2 inline-block", {
    "bg-primary": variant === "primary",
    "bg-black": variant === "black",
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: subHeadingClass }
  return <h3 {...rest}>{children}</h3>
}

interface ILead extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode
  variant?: "dark" | "light"
}
export const Lead: React.FC<ILead> = ({ children, variant, ...rest }) => {
  const leadClass = cn("text-lg text-black font-bold", {
    "!text-white": variant === "light",
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: leadClass }
  return <p {...rest}>{children}</p>
}
