import cn from "classnames"
import Image from "next/image"

import logoLightImg from "public/logo-light.png"
import logoImg from "public/logo.png"

import type { ImageProps } from "next/image"

const Logo: React.FC<Omit<ImageProps, "src" | "alt"> & { light?: boolean }> = ({
  light,
  ...rest
}) => {
  const c = cn("w-[calc(150px+5vw)] h-auto flex", {
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: c }
  return (
    <Image
      src={light ? logoLightImg : logoImg}
      alt="Logo"
      sizes="200px"
      priority
      {...rest}
    />
  )
}

export default Logo
