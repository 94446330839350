import React, { forwardRef } from "react"

import cn from "classnames"
import Link from "next/link"

interface INavLink extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
  stuck?: boolean
  ref?: React.Ref<HTMLAnchorElement>
  as?: React.ElementType
}
const NavLink: React.FC<INavLink> = forwardRef(
  (
    { children, stuck, ...rest }: INavLink,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const cls = cn(
      "px-3 py-4 leading-4 hover:text-secondary focus:text-secondary mdmax:text-lg mdmax:font-bold mdmax:w-full whitespace-nowrap font-normal flex items-center mdmax:justify-center",
      {
        "lgmin:text-white": stuck,
        [rest.className as string]: !!rest.className
      }
    )
    const Tag = rest.as ? rest.as : Link
    if (rest.as) {
      delete rest.as
    }
    rest = { ...rest, className: cls }
    return (
      <Tag ref={ref} {...rest}>
        {children}
      </Tag>
    )
  }
)
NavLink.displayName = "NavLink"

export default NavLink
