import cn from "classnames"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"

import Footer from "components/Footer"
import Header from "components/header/Header"

import RequestCallback from "./forms/RequestCallback"
import Icon from "./Icon"
import { Heading } from "./Text"

const CookieNotice = dynamic(() => import("components/CookieNotice"))

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter()
  const pagesToHideStuffOn = [
    "/404",
    "/500",
    "/contact-us",
    "/sign-in",
    "/admin",
    "/checkout",
    "/order-confirmation"
  ]

  return (
    <>
      <Header />
      <main>
        {children}
        {!pagesToHideStuffOn.includes(router.pathname) && (
          <Section variant="primary" className="text-center">
            <Container>
              <Icon className="w-[100px] mx-auto" />
              <Heading variant="light" alignment="center" underline={false}>
                Request callback
              </Heading>
              <RequestCallback />
            </Container>
          </Section>
        )}
      </main>
      <Footer />
      <CookieNotice />
    </>
  )
}
export default Layout

export type TSectionVariants =
  | "primary"
  | "secondary"
  | "white"
  | "black"
  | "gradient"
  | "gradient-light"
  | "gray-100"
  | "gray-200"
  | "gray-300"
  | "gray-400"
  | "gray-500"
  | "gray-600"
  | "gray-700"
  | "gray-800"
  | "gray-900"

interface ISection extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  variant?: TSectionVariants
  separatorStart?: boolean
  separatorEnd?: boolean
  separatorStartPullUp?: boolean
  separatorEndPullBottom?: boolean
}

export const Section: React.FC<ISection> = ({
  children,
  variant = "white",
  ...rest
}) => {
  const variants = {
    primary: "bg-primary",
    secondary: "bg-secondary",
    white: "bg-white",
    black: "bg-black",
    gradient: "gradient",
    "gradient-light": "bg-gradient-to-r from-white to-transparent",
    "gray-100": "bg-gray-100",
    "gray-200": "bg-gray-200",
    "gray-300": "bg-gray-300",
    "gray-400": "bg-gray-400",
    "gray-500": "bg-gray-500",
    "gray-600": "bg-gray-600",
    "gray-700": "bg-gray-700",
    "gray-800": "bg-gray-800",
    "gray-900": "bg-gray-900 text-white"
  }
  const sectionClass = cn("relative py-6", [variants[variant]], {
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: sectionClass }
  return <section {...rest}>{children}</section>
}

interface IContainer extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "container" | "full" | "left" | "right"
  children?: React.ReactNode
}

export const Container: React.FC<IContainer> = ({
  variant = "container",
  children,
  ...rest
}) => {
  const containerClass = cn({
    container: variant === "container",
    "mx-auto px-5": variant === "full",
    "pl-10": variant === "left",
    "pr-10": variant === "right",
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: containerClass }
  return <div {...rest}>{children}</div>
}

interface IRow extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  gap?: boolean
}
export const Row: React.FC<IRow> = ({ children, gap = true, ...rest }) => {
  const rowClass = cn("flex flex-wrap", {
    "gap-6": gap,
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: rowClass }

  return <div {...rest}>{children}</div>
}
