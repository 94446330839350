import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import type { IconName, SizeProp } from "@fortawesome/fontawesome-svg-core"
import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome"

export type FaLibraries =
  | "brands"
  | "regular"
  | "solid"
  | "light"
  | "duotone"
  | "thin"

interface FaIcon extends FontAwesomeIconProps {
  icon: IconName
  size?: SizeProp
  library?: FaLibraries
  spin?: boolean
}

const FaIcon: React.FC<FaIcon> = ({
  icon,
  size,
  library = "solid",
  spin,
  ...rest
}) => {
  return (
    <FontAwesomeIcon
      icon={`fa-${library} fa-${icon}` as IconName}
      size={size}
      spin={spin}
      {...rest}
    />
  )
}

export default FaIcon
