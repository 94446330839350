import Image from "next/image"

import { Button } from "components/Button"
import { Container, Section } from "components/Layout"
import { SubHeading } from "components/Text"
import accessControlImg from "public/access-control.webp"
import commercialCCTVImg from "public/commercial-cctv.webp"
import domesticCCTVImg from "public/domestic-cctv.webp"
import emergenciesImg from "public/emergencies.webp"
import intruderAlarmImg from "public/intruder-alarm.webp"
import maintenanceImg from "public/maintenance-packages.webp"

import type { StaticImageData } from "next/image"
const GridItem: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    imgSrc: StaticImageData
    imgAlt: string
    heading: string
    text: string
    href?: string
  }
> = ({ imgSrc, imgAlt, heading, text, href }) => {
  return (
    <div className="relative text-white bg-black">
      <Image
        src={imgSrc}
        alt={imgAlt}
        placeholder="blur"
        className="w-full aspect-square object-cover"
      />
      <SubHeading className="absolute top-3 left-0">{heading}</SubHeading>
      <div className="p-3">
        <p>{text}</p>
        {href && (
          <Button variant="outlinePrimary" href={href}>
            Learn more
          </Button>
        )}
      </div>
    </div>
  )
}

const ServicesGrid: React.FC = () => {
  return (
    <Section>
      <Container className="grid smmax:grid-cols-1 mdmin:grid-cols-3 gap-4">
        <GridItem
          imgSrc={domesticCCTVImg}
          imgAlt="Domestic CCTV Derbyshire"
          heading="Home CCTV"
          text="Protect your home with a HD CCTV system. Security cameras are a great security feature and visual deterrent and a perfect addition to your home security system."
          href="/services/home-cctv"
        />
        <GridItem
          imgSrc={commercialCCTVImg}
          imgAlt="Commercial CCTV Derbyshire"
          heading="Commercial CCTV"
          text="CCTV for your business can often be a necessity for H&amp;S, thefts, insurance, production and ultimate all-round protection and security for your commercial premises."
          href="/services/commercial-cctv"
        />
        <GridItem
          imgSrc={intruderAlarmImg}
          imgAlt="Intruder Alarms Derbyshire"
          heading="Intruder alarms"
          text="An intruder alarm install is the perfect way to protect your home or business. Our wireless and wired burglar alarms are the heart of your security system."
          href="/services/intruder-alarms"
        />
        <GridItem
          imgSrc={accessControlImg}
          imgAlt="Access control Derbyshire"
          heading="Access control"
          text="Be in control of who accesses your premises with a state-of-the-art access control system. Anything from a one door entry system to a large access control network that clocks staff in."
          href="/services/access-control"
        />
        <GridItem
          imgSrc={maintenanceImg}
          imgAlt="CCTV maintenance Derbyshire"
          heading="Maintenance"
          text="We have a selection of maintenance packages suitable for both commercial and residential customers. Keep your system up to date and your belongings secure."
          href="/services/maintenance"
        />
        <GridItem
          imgSrc={emergenciesImg}
          imgAlt="CCTV emergencies Derbyshire"
          heading="Emergencies"
          text="We operate an emergency callout repair and replacement service. Our team of security technicians can attend your home or place of work to assist with your security emergency and quickly restore your security system to full working order."
        />
      </Container>
    </Section>
  )
}

export default ServicesGrid
