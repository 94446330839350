import { useEffect } from "react"

import "styles/global.scss"
import "styles/fonts.scss"

// see https://fontawesome.com/v6.0/docs/web/use-with/react/use-with#next-js
import {
  config as faConfig,
  library as faLibrary
} from "@fortawesome/fontawesome-svg-core"
// see https://fontawesome.com/v6.0/docs/web/use-with/react/use-with#troubleshooting-with-next-js
// import "@fortawesome/fontawesome-svg-core/styles.css"
import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css"
import {
  faFacebook,
  faFacebookMessenger,
  faGoogle,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
  faYoutube
} from "@fortawesome/free-brands-svg-icons"
import {
  faArrowLeftLong,
  faArrowRightLong,
  faBackwardStep,
  faBadgeCheck,
  faBlockQuote,
  faBold,
  faCalendarDay,
  faCameraHome,
  faCaretDown,
  faCartShopping,
  faCctv,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronsLeft,
  faChevronsRight,
  faCircleExclamation,
  faCode,
  faCodeSimple,
  faEnvelope,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFileCsv,
  faH1,
  faH2,
  faH3,
  faH4,
  faH5,
  faH6,
  faHorizontalRule,
  faImage,
  faInfo,
  faInfoCircle,
  faItalic,
  faKeyboard,
  faListOl,
  faListUl,
  faLocationArrow,
  faMinus,
  faParagraph,
  faPen,
  faPhone,
  faPlus,
  faQuoteLeft,
  faRedo,
  faRocketLaunch,
  faSave,
  faSearch,
  faShieldCheck,
  faSpinnerThird,
  faStar,
  faStrikethrough,
  faSync,
  faTimes,
  faTrash,
  faTurnDownLeft,
  faUndo,
  faWandMagicSparkles
} from "@fortawesome/pro-solid-svg-icons"
import { Provider as JotaiProvider } from "jotai"
import Script from "next/script"
import { SessionProvider } from "next-auth/react"
import NProgress from "nprogress"
import { SWRConfig } from "swr"

import { pageview } from "lib/gtm/pageview"
import { ScrollTrigger } from "utils/gsap"

import type { AppProps, NextWebVitalsMetric } from "next/app"

const frontendBrandIcons = [
  faFacebook,
  faTwitter,
  faInstagram,
  faWhatsapp,
  faGoogle,
  faYoutube,
  faLinkedinIn,
  faFacebookMessenger
]

const frontEndSolidIcons = [
  faArrowLeftLong, // LogosSlider, ProjectFooter
  faArrowRightLong, // ...
  faSearch, // search form, checkout address search
  faKeyboard, // checkout address search
  faEnvelope, // contact-details, share
  faLocationArrow, // contact-details
  faPhone, // contact-details
  faTimes, // modal, cart
  faCaretDown, // dropdown, accordion
  faExternalLink, // href
  faQuoteLeft, // testimonials
  faCheckCircle, // alert
  faCheck, // admin time
  faCircleExclamation, // alert
  faMinus, // cart
  faPlus, // cart
  faCartShopping, //checkout alert
  faChevronDown, // select, dropdown in menu
  faShieldCheck, // guaranteed home
  faCalendarDay, // book online
  faSave, // admin area update pass
  faEye, // admin area update pass
  faEyeSlash, // admin area update pass
  faCctv, // secure home
  faCameraHome, // high def home
  faBadgeCheck, // experienced home
  faEye, // monitor anywhere home
  faChevronLeft, // prev button
  faChevronRight, // next button
  faStar, // reviews
  faBold, // tip tap
  faItalic, // ...
  faStrikethrough, // ...
  faCode, // tip tag for code block...
  faCodeSimple, // tip tap for code...
  faParagraph, // ...
  faH1, // ...
  faH2, // ...
  faH3, // ...
  faH4, // ...
  faH5, // ...
  faH6, // ...
  faListUl, // ...
  faListOl, // ...
  faBlockQuote, // ...
  faHorizontalRule, // ...
  faTurnDownLeft, // ...
  faUndo, // ...
  faRedo, // ...
  faImage, // ...
  faTrash, // admin area delete post
  faPen, // ...
  faPlus, // for useFieldArray on product id page
  faSpinnerThird, // icon button
  faTimes, // image remove admin
  faChevronRight, // admin area pagination on table
  faChevronLeft, // ...
  faChevronsRight, // ...
  faChevronsLeft, // ...
  faChevronDown, // ...
  faChevronUp, // admin table
  faInfo, // booking page help
  faInfoCircle, // alert
  faRocketLaunch, // deploy in admin area
  faWandMagicSparkles, // magic link on sign in
  faBackwardStep, // back to site in app
  faSync, // for syncing google calendar
  faFileCsv // for csv export
]
faConfig.autoAddCss = false
faLibrary.add(...frontendBrandIcons, ...frontEndSolidIcons)

export default function App({
  Component,
  pageProps,
  router
}: AppProps): React.ReactElement {
  useEffect(() => {
    const handleStart = () => NProgress.start()
    const handleStop = () => {
      NProgress.done()
      ScrollTrigger.refresh()
    }

    router.events.on("routeChangeStart", handleStart)
    router.events.on("routeChangeComplete", handleStop)
    router.events.on("routeChangeError", handleStop)
    router.events.on("routeChangeComplete", pageview)

    return () => {
      router.events.off("routeChangeStart", handleStart)
      router.events.off("routeChangeComplete", handleStop)
      router.events.off("routeChangeError", handleStop)
      router.events.off("routeChangeComplete", pageview)
    }
  }, [router])

  return (
    <SWRConfig
      value={{
        fetcher: (resource: RequestInfo, init: RequestInit): Promise<JSON> =>
          fetch(resource, init).then(res => res.json()),
        // This config is same as immutable mode - https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
        // TODO CHECK SETTINGS AS CAUSING ISSUE WITH MUTATES
        revalidateOnFocus: false
        // revalidateOnReconnect: false,
        // revalidateIfStale: false
      }}
    >
      <SessionProvider session={pageProps.session}>
        <JotaiProvider>
          {process.env.NODE_ENV === "production" && (
            <Script
              strategy="afterInteractive"
              id="google-tag-manager"
              dangerouslySetInnerHTML={{
                __html:
                  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
                  `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
                  `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
                  `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
                  `})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`
              }}
            />
          )}
          <Component {...pageProps} />
        </JotaiProvider>
      </SessionProvider>
    </SWRConfig>
  )
}

// See - https://nextjs.org/docs/advanced-features/measuring-performance#sending-results-to-analytics
export function reportWebVitals(metric: NextWebVitalsMetric): void {
  const { id, name, label, value } = metric
  if (process.env.NODE_ENV === "production" && typeof window !== "undefined") {
    window?.dataLayer?.push({
      event: label,
      event_category:
        label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      event_action: name,
      event_value: Math.round(name === "CLS" ? value * 1000 : value),
      event_label: id,
      non_interaction: true
    })
  }
}
