import cn from "classnames"

import FaIcon from "components/FontAwesomeIcon"

import Href from "./Href"

const sl = JSON.parse(process.env.NEXT_PUBLIC_SOCIAL)

const SocialLinks: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    variant?: "light" | "dark"
  }
> = ({ variant = "dark", ...rest }) => {
  const wrapperClass = cn("flex items-center gap-3", {
    "text-white": variant === "light",
    "text-black": variant === "dark",
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: wrapperClass }
  return (
    <div {...rest}>
      {sl.facebook && (
        <Href
          noUnderline
          externalIconDisabled
          href={sl.facebook}
          title="Link to our Facebook page"
        >
          <FaIcon icon="facebook" library="brands" size="lg" />
        </Href>
      )}
      {sl.twitter && (
        <Href
          noUnderline
          externalIconDisabled
          href={sl.twitter}
          title="Link to our Twitter page"
        >
          <FaIcon icon="twitter" library="brands" size="lg" />
        </Href>
      )}
      {sl.instagram && (
        <Href
          noUnderline
          externalIconDisabled
          href={sl.instagram}
          title="Link to our Instagram page"
        >
          <FaIcon icon="instagram" library="brands" size="lg" />
        </Href>
      )}
      {sl.whatsapp && (
        <Href
          noUnderline
          externalIconDisabled
          href={sl.whatsapp}
          title="Link to our WhatsApp page"
        >
          <FaIcon icon="whatsapp" library="brands" size="lg" />
        </Href>
      )}
      {sl.youtube && (
        <Href
          noUnderline
          externalIconDisabled
          href={sl.youtube}
          title="Link to our YouTube page"
        >
          <FaIcon icon="youtube" library="brands" size="lg" />
        </Href>
      )}
      {sl.linkedin && (
        <Href
          noUnderline
          externalIconDisabled
          href={sl.linkedIn}
          title="Link to our LinkedIn page"
        >
          <FaIcon icon="linkedin-in" library="brands" size="lg" />
        </Href>
      )}
      {sl.messenger && (
        <Href
          noUnderline
          externalIconDisabled
          href={sl.messenger}
          title="Link to our Messenger page"
        >
          <FaIcon icon="facebook-messenger" library="brands" size="lg" />
        </Href>
      )}
    </div>
  )
}

export default SocialLinks
