const colors = require("tailwindcss/colors")
const defaultTheme = require("tailwindcss/defaultTheme")
const plugin = require("tailwindcss/plugin")

module.exports = {
  content: [
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./components/**/*.{js,ts,jsx,tsx}",
    "./utils/**/*.{js,ts,jsx,tsx}" // for generateColorClassesForClassNames - Notion util
  ],
  theme: {
    colors: {
      primary: "#f1793b",
      secondary: "#fb4103",
      black: "#222222",
      white: "#ffffff",
      gray: { ...colors.slate },
      transparent: "transparent",
      current: "currentColor",
      success: colors.emerald[500],
      "success-dark": colors.emerald[600],
      error: colors.rose[500],
      "error-dark": colors.rose[600],
      warning: colors.amber[500],
      "warning-dark": colors.amber[600]
    },

    container: {
      center: true,
      padding: {
        DEFAULT: "1rem",
        smmin: "2rem",
        mdmin: "3rem",
        lgmin: "4rem",
        xlmin: "5rem",
        "2xlmin": "6rem"
      }
    },

    spacing: {
      0: "0",
      1: "0.3rem",
      2: "0.6rem",
      3: "1.2rem",
      4: "1.8rem",
      5: "2.4rem",
      6: "3rem",
      7: "3.6rem",
      8: "4.2rem",
      9: "5.4rem",
      10: "7.2rem",
      11: "10.2rem" // 3 + 10 for some clip masks
    },

    fontFamily: {
      p: "Montserrat, ui-sans-serif, system-ui, -apple-system,BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
      h: "Montserrat, ui-sans-serif, system-ui, -apple-system,BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
    },

    /**
     * Font size
     * 1st in array is the size, 2nd is line-height
     */
    fontSize: {
      xs: [".75rem", "1.75rem"],
      sm: [".8rem", "1.8rem"],
      base: ["1.15rem", "2.15rem"],
      lg: ["1.5rem", "2.25rem"],
      xl: ["2rem", "3rem"],
      headingSm: ["1.5rem", "2.5rem"],
      headingBase: ["2rem", "3rem"],
      headingLg: ["2.5rem", "3.5rem"],
      headingXl: ["3rem", "4rem"]
    },

    letterSpacing: {
      ...defaultTheme.letterSpacing,
      widest: "0.25em"
    },

    fontWeight: {
      light: 400,
      normal: 500,
      bold: 700
    },

    scale: {
      175: "1.75",
      ...defaultTheme.scale
    },

    // See https://tailwindcss.com/docs/breakpoints#max-width-breakpoints
    screens: {
      xsmin: "500px",
      xsmax: { max: "639px" },
      smmin: "640px",
      smmax: { max: "767px" },
      mdmin: "768px",
      mdmax: { max: "1023px" },
      lgmin: "1024px",
      lgmax: { max: "1279px" },
      xlmin: "1280px",
      xlmax: { max: "1535px" },
      "2xlmin": "1536px"
    },

    extend: {
      gridTemplateColumns: {
        auto: "repeat(auto-fit,minmax(0,1fr))"
      }
    }
  },
  plugins: [
    plugin(function ({ addUtilities, theme }) {
      addUtilities({
        ".gradient": {
          backgroundImage: `linear-gradient(to right, ${theme(
            "colors.primary"
          )}, ${theme("colors.secondary")})`
        },
        ".no-prose-list": {
          "& li": {
            paddingLeft: 0
          },
          "&:before, & li:before": {
            content: "none"
          }
        }
      })
    })
  ]
}
