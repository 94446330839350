import Head from "next/head"
import { useRouter } from "next/router"

import googleReviewsData from "cache/google-reviews.json"
import { capitalize } from "utils/helpers"
import { twTheme } from "utils/tw"

const SEO: React.FC<{
  pageTitle: string
  titleTag: string
  metaDescription: string
  ogImage?: string
  extraSchema?: Array<unknown>
  noIndex?: boolean
}> = ({
  pageTitle,
  titleTag,
  metaDescription,
  ogImage,
  extraSchema,
  noIndex
}) => {
  const router = useRouter()
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL
  const currentUrl = (siteUrl + router.asPath).replace(/\/+$/, "")
  const siteName = process.env.NEXT_PUBLIC_SITE_NAME
  const mainTel = process.env.NEXT_PUBLIC_TEL
  const mainEmail = process.env.NEXT_PUBLIC_EMAIL
  const socialLinks = process.env.NEXT_PUBLIC_SOCIAL
  const logo = `${siteUrl}/logo.png`
  const themeColor = twTheme.colors.black
  const description = process.env.NEXT_PUBLIC_SITE_DESCRIPTION
  const lang = process.env.NEXT_PUBLIC_SITE_LANG

  const twitterAuthor = JSON.parse(socialLinks)?.twitter
    ? new URL(JSON.parse(socialLinks).twitter)?.pathname?.replace("/", "")
    : null

  const image =
    ogImage ||
    `${process.env.NEXT_PUBLIC_SITE_URL}/api/og${
      pageTitle.toLowerCase() !== "home" ? `?heading=${pageTitle}` : ""
    }`

  const WebSiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": `${siteUrl}/#WebSite`,
    url: siteUrl,
    name: siteName,
    description,
    publisher: {
      "@id": `${siteUrl}/#LocalBusiness`
    },
    potentialAction: [
      {
        "@type": "SearchAction",
        target: {
          "@type": "EntryPoint",
          urlTemplate: `${siteUrl}/search?q={search_term_string}`
        },
        "query-input": "required name=search_term_string"
      }
    ],
    inLanguage: lang
  }

  const ImageObjectSchema = {
    "@context": "https://schema.org",
    "@type": "ImageObject",
    inLanguage: lang,
    "@id": `${currentUrl}/#ImageObject`,
    url: image,
    contentUrl: image,
    caption: pageTitle
  }

  const WebPageSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": `${currentUrl}/#WebPage`,
    url: currentUrl,
    name: titleTag,
    isPartOf: {
      "@id": `${siteUrl}/#WebSite`
    },
    primaryImageOfPage: {
      "@id": `${currentUrl}/#ImageObject`
    },
    description: metaDescription,
    breadcrumb: {
      "@id": `${currentUrl}/#BreadcrumbList`
    },
    inLanguage: lang,
    potentialAction: [
      {
        "@type": "ReadAction",
        target: [currentUrl]
      }
    ]
  }

  const BreadcrumbListSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "@id": `${currentUrl}/#BreadcrumbList`,
    itemListElement:
      router.asPath === "/"
        ? [
            {
              "@type": "ListItem",
              position: 1,
              item: siteUrl,
              name: "Home"
            }
          ]
        : router.asPath.split("/").map((path, index) => ({
            "@type": "ListItem",
            item: `${siteUrl}/${path}`,
            name: path ? capitalize(path) : "Home",
            position: index + 1
          }))
  }

  const LocalBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "@id": `${siteUrl}/#LocalBusiness`,
    additionalType: [
      "https://www.productontology.org/id/Closed-circuit_television",
      "https://www.productontology.org/id/Closed-circuit_television_camera",
      "https://www.productontology.org/id/Wireless_security_camera"
    ],
    url: siteUrl,
    name: siteName,
    foundingDate: "2017",
    foundingLocation: "Derbyshire",
    legalName: process.env.NEXT_PUBLIC_SITE_NAME,
    description,
    logo,
    image: {
      "@id": `${currentUrl}/#ImageObject`
    },
    telephone: mainTel,
    email: mainEmail,
    address: {
      "@type": "PostalAddress",
      "@id": `${siteUrl}/#PostalAddress`,
      streetAddress: "3-5 Church Street",
      addressLocality: "Ripley",
      addressRegion: "Derbyshire",
      postalCode: "DE5 3BU",
      addressCountry:
        process.env.NEXT_PUBLIC_SITE_LANG === "en-AU" ? "AU" : "GB"
    },
    geo: {
      "@type": "GeoCoordinates",
      "@id": `${siteUrl}/#GeoCoordinates`,
      latitude: 53.05102916348825,
      longitude: -1.4067462779106068
    },
    openingHours: ["Mo,Tu,We,Th,Fr,Sa 08:00-20:00", "Su Closed"],
    contactPoint: {
      "@type": "ContactPoint",
      telephone: `${mainTel}`,
      email: `${mainEmail}`,
      contactType: "sales",
      areaServed: process.env.NEXT_PUBLIC_SITE_LANG === "en-AU" ? "AU" : "GB",
      availableLanguage: "English"
    },
    sameAs: Object.values(JSON.parse(socialLinks)),
    priceRange: "$$$",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: googleReviewsData.result.rating,
      bestRating: 5,
      worstRating: 1,
      ratingCount: googleReviewsData.result.user_ratings_total,
      url: "https://www.google.com/search?q=3hcctv+derbyshire"
    }
  }

  return (
    <Head>
      {/* Main */}
      <title>{titleTag}</title>
      <meta name="description" content={metaDescription} />

      {noIndex && <meta name="robots" content="noindex" />}

      {/* App stuff */}
      <meta name="application-name" content={siteName} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content={siteName} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="msapplication-TileColor" content={themeColor} />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="theme-color" content={themeColor} />

      {/* Favicon */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/icon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/icon-16x16.png"
      />
      <link rel="manifest" href="/favicon/manifest.json" />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content={themeColor} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={currentUrl} />
      <meta name="twitter:title" content={titleTag} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />

      {twitterAuthor && (
        <>
          <meta name="twitter:site" content={`@${twitterAuthor}`} />
          <meta name="twitter:creator" content={`@${twitterAuthor}`} />
        </>
      )}

      {/* Open graph */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={titleTag} />
      <meta
        property="og:locale"
        content={
          process.env.NEXT_PUBLIC_SITE_LANG === "en-AU" ? "en_AU" : "en_GB"
        }
      />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:image" content={image} />

      {/* Other */}
      <link rel="canonical" href={currentUrl} />

      {/* Schema */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              LocalBusinessSchema,
              WebPageSchema,
              WebSiteSchema,
              ImageObjectSchema,
              BreadcrumbListSchema,
              ...(extraSchema && extraSchema.length > 0 ? extraSchema : [])
            ]
          })
        }}
      />

      {/* Preloads */}
      <link
        rel="preload"
        href="/fonts/Montserrat-400.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/Montserrat-500.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/Montserrat-700.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Head>
  )
}

export default SEO
