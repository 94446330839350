import cn from "classnames"

import Href from "components/Href"

import FaIcon from "./FontAwesomeIcon"

import type { IconName } from "@fortawesome/fontawesome-svg-core"
import type { THrefVariants } from "types/types"

const wrapperClass = "flex items-center"
const iconClass = "h-3 w-3 mr-3"

interface ITelephone extends React.HTMLAttributes<HTMLAnchorElement> {
  variant?: THrefVariants
  icon?: IconName
  tel?: string
}
export const Telephone: React.FC<ITelephone> = ({
  variant = "current",
  icon,
  tel,
  ...rest
}) => {
  const telephoneClass = cn("whitespace-nowrap", {
    [rest?.className as string]: !!rest?.className
  })
  const iconC = cn(iconClass, {
    "text-white": variant === "light",
    "text-black": variant === "dark"
  })
  rest = { ...rest, className: telephoneClass }
  return (
    <div className={wrapperClass}>
      <FaIcon icon={icon || "phone"} className={iconC} />
      <Href
        href={`tel:${(tel || process.env.NEXT_PUBLIC_TEL).replace(
          /[()\s]/g,
          ""
        )}`}
        title={`Call us on ${tel || process.env.NEXT_PUBLIC_TEL}`}
        variant={variant}
        {...rest}
      >
        {tel || process.env.NEXT_PUBLIC_TEL}
      </Href>
    </div>
  )
}

interface IEmail extends React.HTMLAttributes<HTMLDivElement> {
  variant?: THrefVariants
  icon?: IconName
  email?: string
}
export const Email: React.FC<IEmail> = ({ variant, icon, email, ...rest }) => {
  const emailClass = cn(wrapperClass, {
    "text-white": variant === "light",
    "text-black": variant === "dark",
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: emailClass }
  return (
    <div {...rest}>
      <FaIcon icon={icon || "envelope"} className={iconClass} />
      <Href
        externalIconDisabled
        href={`mailto:${email || process.env.NEXT_PUBLIC_EMAIL}`}
        title={`Email us at ${email}`}
        variant="current"
      >
        {email || process.env.NEXT_PUBLIC_EMAIL}
      </Href>
    </div>
  )
}

interface IAddress extends React.HTMLAttributes<HTMLDivElement> {
  variant?: THrefVariants
  icon?: IconName
  address?: string
}
export const Address: React.FC<IAddress> = ({
  variant,
  icon,
  address,
  ...rest
}) => {
  const addressClass = cn(wrapperClass, {
    "text-white": variant === "light",
    "text-black": variant === "dark",
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: addressClass }
  return (
    <div {...rest}>
      <FaIcon icon={icon || "location-arrow"} className={iconClass} />
      <address className="not-italic">
        {address || process.env.NEXT_PUBLIC_ADDRESS}
      </address>
    </div>
  )
}

export const InlineEmail: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & { icon?: boolean }
> = ({ icon, ...rest }) => {
  return (
    <Href
      href={`mailto:${process.env.NEXT_PUBLIC_EMAIL}`}
      title={`Email us on ${process.env.NEXT_PUBLIC_EMAIL}`}
      noUnderline
      {...rest}
    >
      {icon && <FaIcon icon="envelope" className="mr-2" />}
      {process.env.NEXT_PUBLIC_EMAIL}
    </Href>
  )
}

export const InlineTelephone: React.FC<
  React.HTMLAttributes<HTMLAnchorElement> & { icon?: boolean }
> = ({ icon, ...rest }) => {
  return (
    <Href
      href={`tel:${process.env.NEXT_PUBLIC_TEL.replace(/[()\s]/g, "")}`}
      title={`Call us on ${process.env.NEXT_PUBLIC_TEL}`}
      noUnderline
      {...rest}
    >
      {icon && <FaIcon icon="phone" className="mr-2" />}
      {process.env.NEXT_PUBLIC_TEL}
    </Href>
  )
}
