import { useEffect, useRef, useState } from "react"

import cn from "classnames"

import FaIcon from "components/FontAwesomeIcon"
import { gsap } from "utils/gsap"

import type { IconName } from "@fortawesome/fontawesome-svg-core"
import type { AlertVariants } from "types/types"

export type Alert = React.HTMLAttributes<HTMLDivElement> & {
  variant?: AlertVariants
  show: boolean
  icon?: IconName
}

const Alert: React.FC<Alert> = ({ variant, show, children, icon, ...rest }) => {
  const alertRef = useRef<HTMLDivElement>(null)
  const tl = useRef<GSAPTimeline | null>(null)
  const [state, setState] = useState(false)

  const alertClass = cn({
    "flex font-bold justify-between items-center gap-3 w-fit p-3 mt-3 shadow":
      true,
    "bg-success text-white": variant === "success",
    "bg-error text-white": variant === "error",
    "bg-primary text-white": variant === "warning",
    "bg-gray-200": variant === "info",
    [rest?.className as string]: !!rest?.className
  })
  rest = { ...rest, className: alertClass }

  // show prop is passed to component. When this changes, set the state as per below
  useEffect(() => {
    if (show) {
      setState(true)
    } else {
      tl.current?.reverse().then(() => setState(false))
    }
  }, [show])

  // when the state is true, animate in. Animate out like above when show changes
  useEffect(() => {
    if (alertRef.current) {
      tl.current = gsap
        .timeline({
          paused: true
        })
        .fromTo(alertRef.current, { autoAlpha: 0 }, { autoAlpha: 1 })
      if (state) {
        tl.current.play()
      }
      return () => {
        tl.current?.kill()
      }
    }
  }, [state])

  return state ? (
    <div ref={alertRef} {...rest}>
      <div>{children}</div>
      <FaIcon
        icon={
          icon ||
          (variant === "error"
            ? "exclamation-circle"
            : variant === "success"
            ? "check-circle"
            : "info-circle")
        }
        size="lg"
      />
    </div>
  ) : (
    <></>
  )
}

export default Alert
